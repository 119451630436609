import { isEmpty } from "@/el-ui/libs/check.js";

export const parseParams = (option)=>{
	if(isEmpty(option) || option == "number"){
		return {};
	}
	
	let scene = decodeURIComponent(option);
	
	let res = {};
	let arr = scene.split("&");
	for (let i = 0; i < arr.length; i++) {
		let param = arr[i].split("=");
		res[param[0].replace(/(^\s*)|(\s*$)/g, "")] = decodeURIComponent(param[1].replace(/(^\s*)|(\s*$)/g, ""));
	}
	
	return res;
}

/**
 * 解析URL参数
 * @param content 内容
 * @param time    时间
 */
export const scene = (option)=>{
	if((option.scene || null) != null && typeof option.scene != 'number'){
		let res = parseParams(option.scene);
		if(!isEmpty(res.spid)){
			uni.$storage.set("spid",res.spid);
		}
		
		return res;
	}
	
	if(!isEmpty(option.query || null)){
		let params = parseParams(option.query.scene || null);
		if(!isEmpty(params.spid || null)){
			uni.$storage.set("spid",params.spid);
		}
		
		return option.query;
	}
	
	// #ifdef MP
	// if(option.query.hasOwnProperty('scene')){
	// 	switch(option.scene){
	// 		case 1001: // 直接进入小程序
	// 		case 1011: // 扫码
	// 		case 1012: // 长按图片识别二维码
	// 		case 1013: // 手机相册选取二维码
	// 		case 1047: // 扫描小程序码
	// 		case 1048: // 长按图片识别小程序码
	// 		case 1049: // 手机相册选取小程序码
	// 			let scene = decodeURIComponent(option.query.scene);
	// 			let res = {};
	// 			let arr = scene.split("&");
	// 			for(let i = 0; i < arr.length; i++) {
	// 				let param = arr[i].split("=");
	// 				res[param[0].replace(/(^\s*)|(\s*$)/g, "")] = decodeURIComponent(param[1].replace(/(^\s*)|(\s*$)/g, ""));
	// 			}
	
	// 			if((res.spid != null || res.spid != undefined) && res.spid > 0){
	// 				uni.$storage.set("spid",res.spid);
	// 			}
	
	// 			return res;
	// 	}
	// }
	// #endif
	
	return option;
}