
/**
 * 检查值是否超过1
 */
export const isNumValue = (num)=>{
	return (num > 1 ? 1 : (num < 0 ? 0 : num));
};

/**
 * RGB颜色值转HSL颜色值
 * @param r 红色
 * @param g 绿色
 * @param b 蓝色
 * @returns object
 */
export const rgbToHsl = (r, g, b)=>{
	const hsl = { h: 0, s: 0, l: 0 };

	// 计算rgb基数
	r = r / 255;
	g = g / 255;
	b = b / 255;
	const max = Math.max(r, g, b);
	const min = Math.min(r, g, b);
	const delta = max - min;

	// 计算hue
	let hue = hsl.h;
	if (delta != 0) {
		if (max === r) {
			hue = ((g - b) / delta) % 6;
		} else if (max === g) {
			hue = (b - r) / delta + 2;
		} else {
			hue = (r - g) / delta + 4;
		}
	}

	// 计算lightness
	let lightness = (max + min) / 2;

	// 计算saturation
	let saturation = hsl.s;
	if (delta != 0) {
		saturation = delta / (1 - Math.abs(2 * lightness - 1));
	}

	// 返回 HSL 颜色值
	hsl.h = hue * 60;
	hsl.s = saturation;
	hsl.l = lightness;

	return hsl;
};


/**
 * RGB颜色值转HSV颜色值
 * @param r 红色
 * @param g 绿色
 * @param b 蓝色
 * @returns object
 */
export const rgbToHsv = (r, g, b)=>{
	const r1 = r / 255;
	const g1 = g / 255;
	const b1 = b / 255;

	const max = Math.max(r1, g1, b1);
	const min = Math.min(r1, g1, b1);
	const delta = max - min;

	let h = 0;
	if (delta != 0) {
		if (max === r1) {
			h = ((g1 - b1) / delta) % 6;
		} else if (max === g1) {
			h = (b1 - r1) / delta + 2;
		} else if (max === b1) {
			h = (r1 - g1) / delta + 4;
		}
	}

	const saturation = max == 0 ? 0 : delta / max;
	const value = max;
	let hue = h * 60;

	// 处理负数情况
	if (hue < 0) {
		hue += 360
	};

	return { h: hue, s: saturation, v: value };
};
/**
 * HSV颜色值转RGB颜色值
 * @param h [0, 360]
 * @param s [0, 1]
 * @param v [0, 1]
 * @returns object
 */
export const hsvToRgb = (h, s, v)=>{
	const hue = h / 60;
	const saturation = isNumValue(s);
	const value = isNumValue(v);

	const chroma = value * saturation;
	const x = chroma * (1 - Math.abs((hue % 2) - 1));
	const m = value - chroma;

	let r = 0;
	let g = 0;
	let b = 0;

	if (hue >= 0 && hue < 1) {
		r = chroma;
		g = x;
		b = 0;
	} else if (hue >= 1 && hue < 2) {
		r = x;
		g = chroma;
		b = 0;
	} else if (hue >= 2 && hue < 3) {
		r = 0;
		g = chroma;
		b = x;
	} else if (hue >= 3 && hue < 4) {
		r = 0;
		g = x;
		b = chroma;
	} else if (hue >= 4 && hue < 5) {
		r = x;
		g = 0;
		b = chroma;
	} else if (hue >= 5 && hue < 6) {
		r = chroma;
		g = 0;
		b = x;
	}

	return { 
		r: Math.round((r + m) * 255), 
		g: Math.round((g + m) * 255), 
		b: Math.round((b + m) * 255) 
	};
};

/**
 * HSL颜色值转换为RGB颜色值
 * @param h [0, 360]
 * @param s [0, 1]
 * @param l [0, 1]
 * @returns object
 */
export const hslToRgb = (h, s, l)=>{
	let rgb 		 = { r: 0, g: 0, b: 0 };
	const hue 		 = h / 360;
	const saturation = isNumValue(s);
	const lightness  = isNumValue(l);

	// 计算相关值
	const chroma   = (1 - Math.abs(2 * lightness - 1)) * saturation;
	const huePrime = hue * 6;
	const x 	   = chroma * (1 - Math.abs((huePrime % 2) - 1));
	const m        = lightness - chroma / 2;

	// 初始化 RGB 值
	let r = 0;
	let g = 0;
	let b = 0;

	// 根据 huePrime 计算 RGB 值
	if (huePrime >= 0 && huePrime < 1) {
		r = chroma;
		g = x;
	} else if (huePrime >= 1 && huePrime < 2) {
		r = x;
		g = chroma;
	} else if (huePrime >= 2 && huePrime < 3) {
		g = chroma;
		b = x;
	} else if (huePrime >= 3 && huePrime < 4) {
		g = x;
		b = chroma;
	} else if (huePrime >= 4 && huePrime < 5) {
		r = x;
		b = chroma;
	} else if (huePrime >= 5 && huePrime < 6) {
		r = chroma;
		b = x;
	}
	
	// 返回 RGB 颜色值
	return {
		r: Math.round((r + m) * 255),
		g: Math.round((g + m) * 255),
		b: Math.round((b + m) * 255)
	};
};

/**
 * HEX 十六进制颜色值转换为 RGB 颜色值
 * @param hex HEX十六进制颜色值
 * @returns object
 */
export const hexToRgb = (hex)=>{
	const hexRegex = /^#?([a-fA-F0-9]{6})$/;
	if (!hexRegex.test(hex)) {
		throw new Error('hex颜色值格式错误');
	}

	const cleanHex = hex.replace('#', '');
	return { 
		r: parseInt(cleanHex.slice(0, 2), 16), 
		g: parseInt(cleanHex.slice(2, 4), 16), 
		b: parseInt(cleanHex.slice(4, 6), 16)
	};
};

/**
 * 获取 RGB 中单个值中对应的 HEX 颜色值
 * @param value RGB中的单个值，即r/g/b中的某个值
 * @returns string
 */
export const rgbToHexValue = (value)=>{
	return value.toString(16).padStart(2, '0');
};

/**
 * RGB 颜色值转为 HEX 十六进制颜色值
 * @param r 红色
 * @param g 绿色
 * @param b 蓝色
 * @returns string
 */
export const rgbToHex = (r, g, b)=>{
	const hexR = rgbToHexValue(r);
	const hexG = rgbToHexValue(g);
	const hexB = rgbToHexValue(b);
	const hexColor = `#${hexR}${hexG}${hexB}`;
	return hexColor.toUpperCase();
};

/**
 * HSL 颜色值转为 HEX 十六进制颜色值
 * @param h hue色相，0-360范围之间
 * @param s saturation饱和度，0-1之间
 * @param l lightness亮度，0-1之间
 * @returns string
 */
export const hslToHex = (h, s, l)=>{
	const { r, g, b } = hslToRgb(h, s, l);
	return rgbToHex(r, g, b).toUpperCase();
};

/**
 * HSV 颜色值转为 HEX 十六进制颜色值
 * @param h hue色相，0-360范围之间
 * @param s saturation饱和度，0-1之间
 * @param v value亮度，0-1之间
 * @returns string
 */
export const hsvToHex = (h, s, v)=>{
	const { r, g, b } = hsvToRgb(h, s, v);
	return rgbToHex(r, g, b).toUpperCase();
};

/**
 * HEX 十六进制颜色值转为 HSV 颜色值
 * @param hex hex颜色值
 * @returns object
 */
export const hexToHsv = (hex)=>{
	const { r, g, b } = hexToRgb(hex);
	return rgbToHsv(r, g, b);
};

/**
 * 浅色系颜色动态梯度算法
 * @param color 基础色，仅支持hex十六进制格式
 * @param index 索引 [1-10]
 * @returns string
 */
export const colorPalette = (originColor, i)=>{
	const color = hexToHsv(originColor);

	const h = color.h;
	const s = color.s * 100;
	const v = color.v * 100;

	const hueStep = 2;
	const maxSaturationStep = 100;
	const minSaturationStep = 9;

	const maxValue = 100;
	const minValue = 30;

	function getNewHue(isLight, i){
		let hue;
		if (h >= 60 && h <= 240) {
			hue = isLight ? h - hueStep * i : h + hueStep * i;
		} else {
			hue = isLight ? h + hueStep * i : h - hueStep * i;
		}
		if (hue < 0) {
			hue += 360;
		} else if (hue >= 360) {
			hue -= 360;
		}
		return Math.round(hue) == 360 ? 0 : Math.round(hue);
	}

	function getNewSaturation(isLight, i){
		let newSaturation;
		if (isLight) {
			newSaturation = s <= minSaturationStep ? s : s - ((s - minSaturationStep) / 5) * i;
		} else {
			newSaturation = s + ((maxSaturationStep - s) / 4) * i;
		}
		
		return newSaturation;
	}

	function getNewValue(isLight, i){
		return isLight ? v + ((maxValue - v) / 5) * i : (v <= minValue ? v : v - ((v - minValue) / 4) * i);
	}

	const isLight = i < 6;
	const index = isLight ? 6 - i : i - 6;
	return i == 6 ? originColor : hsvToHex(getNewHue(isLight, index), getNewSaturation(isLight, index) / 100, getNewValue(isLight, index) / 100);
};

/**
 * 获取基础色对应的调色板，可以直接获取 0-9 10个色阶的调色板集合，也可以根据索引值单独获取调色板的某个颜色
 * @param color  基础色
 * @param num    获取颜色个数
 * @return Array
 */
export const generate = (color = "#ff4500", num = 10)=>{
	const list = [];
	
	for (let i = 1; i <= num; i++) {
		list.push(colorPalette(color, i));
	};
	
	return list;
}



