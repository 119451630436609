import setting from "./config/index.js";
import config from "./libs/config.js";
import check from "./libs/check.js";
import date from "./libs/date.js";
import money from "./libs/money.js";
import storage from "./libs/storage.js";
import style from "./libs/style.js";
import utils from "./libs/utils.js";
import http from "./http/http.js";
import layer from "./libs/layer.js";
import * as color from "./libs/color.js";
import pages from "@/pages.json";

config.set(setting);
if(pages.tabBar.list.length > 0){
	let tabBarList = [];
	for(let i=0; i<pages.tabBar.list.length; i++){
		tabBarList.push(pages.tabBar.list[i].pagePath);
	}
	
	config.set("tabBarList.list",tabBarList);
}

if(config.get("tabBarList.defaultHome","").length <= 0 && pages.pages.length > 0){
	config.set("tabBarList.defaultHome",pages.pages[0].path);
}

uni.$http    = http;
uni.$storage = storage;
uni.$config  = config;
uni.$layer   = layer;
uni.$color   = color;

uni.$e = {
	version: "2.0",
	check:   check,
	date:    date,
	money:   money,
	style:   style,
	utils:   utils
};